import React from "react";
import "../styles/ContactButtons.css"; // Custom CSS
import { useApi } from "../services/ApiContext";


const ContactButtons = ({ contactInfo }) => {
  const { data, loading, error } = useApi();
  const profileData = data?.data[0];

  return (
    <div className="contact-buttons">
      <div className="contact-button">
        <a href={`tel:${profileData?.mobile}`}>
          <img src="/phone.png" alt="Call" className="contact-icon" />
          <p>Call</p>
        </a>
      </div>
      <div className="contact-button">
        <a href={`mailto:${profileData?.email}`}>
          <img src="/email.png" alt="Mail" className="contact-icon" />
          <p>Mail</p>
        </a>
      </div>
      <div className="contact-button">
        <a href={`https://wa.me/${profileData?.whatsapp}`}>
          <img src="/whatsapp.png" alt="WhatsApp" className="contact-icon" />
          <p>WhatsApp</p>
        </a>
      </div>
      <div className="contact-button">
        <a href={profileData?.website} target="_blank" rel="noopener noreferrer">
          <img src="/website.png" alt="Website" className="contact-icon" />
          <p>Website</p>
        </a>
      </div>
    </div>
  );
};


export default ContactButtons;
