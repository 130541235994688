import React from "react";
import { ApiProvider } from "../services/ApiContext";
import ProfileCard from "./ProfileCard";
import ContactButtons from "./ContactButtons";
import Gallery from "./Gallery";
import AboutSection from "./AboutSection";
import SocialMediaLinks from "./SocialMediaLinks";
import FooterButton from "./FooterButton";


function ProfileHome() {
  return (
    
    <ApiProvider>
    <div className="app-container">
      <div className="first-part">
      <ProfileCard />
      <ContactButtons />
      <div className="blog-btn">
          <a href="" > 
          <img src="../shop-icon.png" alt="" srcset="" />
            <p>View Shop</p></a>
      </div>
      <Gallery />
      </div>
      <AboutSection />
      <SocialMediaLinks />
      <FooterButton />
    </div>
    </ApiProvider>
   
  );
}

export default ProfileHome;
