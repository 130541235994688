import React from "react";
import "../styles/FooterButton.css";

function FooterButton() {
  return (
    <div className="footer-button">
      <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalFoot" >Exchange Contact</button>
      <button className="btn">Download Contact</button>
      <div className="modal fade " id="exampleModalFoot" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm modal-centerd">
          <div className="modal-content">
          <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Exchange info with Tap Tap Go</h5>
            </div>
            <div className="modal-body">
            <div className="form-modal-data">
                                               
                        <form >
                            <input type="hidden" name="user_id" value="353"/>
                            <input type="hidden" name="lead_profile_id" value="833"/>

                            <input type="hidden" name="lead_latitude" id="lead_latitude" value="0"/>
                            <input type="hidden" name="lead_longitude" id="lead_longitude" value="0"/>

                            <div className="row">
                                <div className="col-12">
                                    <input type="text" name="lead_name" 
                                     placeholder="Name" className="error" 
                                      aria-invalid="true"/>
                                   <label id="lead_name-error" className="error" htmlFor="lead_name">Please enter name</label>
                                </div>
                                <div className="col-12">
                                    <div className="mob">
                                        <select name="lead_country_code"   className="valid" aria-invalid="false">
                                          <option value="+93/AF">Afghanistan - (+93)</option>
                                           </select>
                                     <input type="text" name="lead_phone_no"  placeholder="Phone" className="error"  aria-invalid="true"/>
                                    </div>
                                    <label id="lead_phone_no-error" className="error" htmlFor="lead_phone_no">Please enter either a mobile no. or a email</label>
                                </div>
                                <div className="col-12">
                                    <input type="text" name="lead_email" placeholder="Email" className="error" />
                                    <label id="lead_email-error" className="error" htmlFor="lead_email">Please enter either a mobile no. or a email</label>
                                </div>

                                <div className="col-12">
                                    <input type="text" name="lead_note"  placeholder="Note"  className="valid" aria-invalid="false"/>
                                </div>
                            </div>
                            <div className="field-dynamic">
                                <a href="#">Additional Fields
                                  
                                </a>
                                <div className="d-none">
                                    <div className="row">
                                        <div className="col-12">
                                            <input type="text" name="lead_company"  placeholder="Company" />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="lead_designation"  placeholder="Designation"/>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="lead_location" placeholder="Location" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-btn">
                                <button type="submit" fdprocessedid="2m4ux5">Continue</button>
                            </div>
                            <div className="modal-footer-data">
                                <p>Or <a href="https://api.ttg.bio/contactcard/ouoth0w3/y29474">Download Contact</a></p>
                                <h6>Tap Go does not share or sell your data.</h6>
                            </div>
                        </form>
                    </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterButton;
