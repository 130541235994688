import React from "react";
import { useApi } from "../services/ApiContext";
import "../styles/AboutSection.css"; // Import CSS for styling


const AboutSection = () => {
  const { data, loading, error } = useApi();
  const profileData = data?.data[0].File;
  
  const files = profileData ?.filter((file) => file.fileType != 'image') // Filter only images
  .map((file) => ({
    name: file.fileName, url : file.fileUrl, fileType : file.fileType
  }
     )) || [];

  return (
    <div className="about-section">
      <div className="container file-section">
        <div className="row file-icons pt-2">
          {files.map((file, index) => (
            <div className="col-sm-2" key={index}>
              <div className="file-item">
                <a href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  <img
                    src={ file?.fileType === "pdf" ? "/pdf-icon.png": file?.fileType === "csv"
                        ? "/file-csv.svg"
                        : "/file-excel.svg" // Default icon
                    }
                    alt={`File ${index + 1}`}
                    className="file-icon"
                  />
                  <p>
                    {file.name.length > 10
                      ? `${file.name.slice(0, 4)}...${file.name.slice(-4)}`
                      : file.name}
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className="about-details">
        {data?.data[0].bios && (
        <div className="about-item">
          <p>{data?.data[0].bios}</p>
        </div>
        )}

        {data?.data[0].companyName && (
          <div className="about-item">{data?.data[0].companyName}</div>
        )}

        {data?.data[0].bio && (
          <div className="about-item">{data?.data[0].bio}</div>
        )}

        {data?.data[0].address && (
          <div className="about-item address-section">
            <div className="address">{data?.data[0].address}</div>
            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(data?.data[0].address)}`} 
              target="_blank" 
              rel="noopener noreferrer">
              <img src="../maps.svg" alt="Open Map" />
            </a>          </div>
        )}
        {data?.data[0].website && (
        <div className="about-item">
          <a href="http://www.abfaco.in" target="_blank" rel="noopener noreferrer">
            {data?.data[0].website}
          </a>
        </div>
        )}

      </div>

    </div>
  );
};

export default AboutSection;
