import React, { useState } from "react";
import { useApi } from "../services/ApiContext";
import "../styles/Gallery.css";

const Gallery = () => {
  const { data, loading, error } = useApi();
  const profileData = data?.data[0].File;

  
  const images = profileData ?.filter((file) => file.fileType === 'image') // Filter only images
  .map((file) => (
     file.fileUrl)) || [];


     const first = document.querySelector('.first-part');
     if(images && images.length > 3){
        if(first){
            first.style.minHeight = '800px';
          }
    }else{
      if(first){
        first.style.minHeight = '680px';
      }

    }
    console.log(first);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const openModal = (index) => {
    setCurrentImageIndex(index);
  };

  // Close modal
  const closeModal = () => {
    setCurrentImageIndex(null);
  };

  // Go to next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 < images.length ? prevIndex + 1 : 0
    );
  };

  // Go to previous image
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : images.length - 1
    );
  };

  return (
    <>
      {/* Gallery Thumbnails */}
      <div className="gallery-container">
        {images.slice(0, 5).map((image, index) => ( //
          <div className="gallery-item" key={index} onClick={() => openModal(index)}>
            <img src={image} alt={`Gallery ${index + 1}`} />
          </div>
        ))}

        {images.length > 5 && (
          <div
            className="gallery-item overlay"
            onClick={() => openModal(5)} // Open modal for remaining images
            style={{
              backgroundImage: `url(${images[5]})`, // Use the 6th image as background
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundBlendMode: 'hue',
              position: 'relative',
            }}
          >
            <span className="overlay-text text-center">+{images.length - 5} Photos</span>
          </div>
        )}

      </div>

      {/* Modal */}
      {currentImageIndex !== null && (
        <div className="modal-overlay gallery-modal">
          <div className="modal-dialog modal-sm modal-centerd">
            <div className="modal-content">
              <button className="close-button" onClick={closeModal}>
                &times;
              </button>
              <button className="prev-button" onClick={prevImage}>
                &lt;
              </button>
              <div className="modal-body">
                <img
                  src={images[currentImageIndex]}
                  alt={`Gallery ${currentImageIndex + 1}`}
                  className="enlarged-image"
                />
              </div>
              <button className="next-button" onClick={nextImage}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
