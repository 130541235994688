import React from "react";
import { useApi } from "../services/ApiContext";
import "../styles/SocialMediaLinks.css";


// const socialMedia = [
//   { name: "Facebook", icon: "./facebook.png", url: "#" },
//   { name: "Twitter", icon: "twitter.png", url: "#" },
//   { name: "Instagram", icon: "instagram.png", url: "#" },
//   { name: "LinkedIn", icon: "linkedin.png", url: "#" },
//   { name: "YouTube", icon: "youtube.png", url: "#" },
//   { name: "Snapchat", icon: "snapchat.png", url: "#" },
//   { name: "Pinterest", icon: "pinterest.png", url: "#" },
//   { name: "WhatsApp", icon: "whatsapp.png", url: "#" },
// ];



function SocialMediaSection() {
  const { data, loading, error } = useApi();
  const socialData = data?.data[0].socialLinks;

  
  const socialMedia = socialData?.map((file) => ({
    name: file.type,
    icon: file.type.toLowerCase() + ".svg",
    url: file.url,
  })) || [];


  return (
    <div className="social-media-section">
      <div className="row">
        {socialMedia.map((item, index) => (
          <div className="col-3 text-center" key={index}>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="social-media-link"
            >
              <img
                src={"../"+ item.icon}
                alt={item.name}
                className="social-media-icon"
              />
              <p className="social-media-name">{item.name}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SocialMediaSection;

