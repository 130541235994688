import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import NotFound from './components/NotFound.jsx';
import ProfileHome from './components/ProfileHome.jsx';

const AppRouter = () => {
  
  return (
    <Router>
      <Routes>
          <Route exact  path="/profile/:profileId"  element={<ProfileHome /> }/>
          <Route path="*" element={<Navigate to="/profile/1" replace />} />
          <Route path="/not-found" element={<NotFound />} />

         </Routes>
    </Router>
  );
};



export default AppRouter;

