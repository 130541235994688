import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { profileId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api.ttn.bio/api/v1/content?profileId=${profileId}`);
        setData(response.data); 
      } catch (err) {
        navigate("/not-found"); 
        setError(err.message); 
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

  return (
    <ApiContext.Provider value={{ data, loading, error }}>
      {children}
    </ApiContext.Provider>
  );
};
