import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/NotFound.css'; // Optional: Include the CSS for styling

const NotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/'); // Redirect to the homepage or a specific route
  };

  return (
    <div className="not-found-container">
      <h1>404</h1>
      <p>Page Not Found</p>
      <button onClick={goHome}>Go to Homepage</button>
    </div>
  );
};

export default NotFound;
