import React from "react";
import { useApi } from "../services/ApiContext";
import "../styles/ProfileCard.css";

function ProfileCard() {
  const { data, loading, error } = useApi();
  const profileData = data?.data[0];
  return (
    <div className="profile-container">
      {/* Background Image */}
      <div className="background-image">
        <img
          src={profileData?.id === 100 ? "/sxcc.jpg " : "/taptapgo_cover.jpg"}
          alt="Profile"
          className="background-image"
        />
      </div>

      {/* Profile Content */}
      <div className="profile-card">
        <img
          src={profileData?.profileImageUrl ?? "/no-profile.png"}
          alt="Profile"
          className="profile-pic"
        />
        <div className="profile-details">
          <h2
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: "bold",
              color: "#131317",
            }}
          >
            {profileData?.fullName ?? "-"}
          </h2>{" "}
          <p style={{ color: "#817979" }}>{profileData?.jobTitle ?? "-"} </p>
          <p className="company" style={{ color: "#131317" }}>{profileData?.companyName ?? "-"}</p>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
